/* eslint-disable react/no-unstable-nested-components */
import React, { useRef } from "react"
import dynamic from "next/dynamic"
import { uid } from "uid"
import Slider from "react-slick"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { useGlobalContext } from "../../context/layout-context"

const PrevButton = dynamic(() => import("../buttons/prev-button"), {
  ssr: false,
})
const NextButton = dynamic(() => import("../buttons/next-button"), {
  ssr: false,
})
const ImageContentful = dynamic(() => import("../contentful/image"), {
  ssr: false,
})
const ButtonPlayVideo = dynamic(() => import("../buttons/button-play-video"), {
  ssr: false,
})
const TitleUnderlined = dynamic(() => import("../title/title-underlined"), {
  ssr: true,
})

function SliderComponent({ imageItems, title, textClasslist }) {
  const { globalState } = useGlobalContext()
  const { isModalOpen } = globalState || {}

  const sliderRef = useRef()
  const stickyBar = useRef()

  function NextArrow() {
    return (
      <NextButton
        classList="flex items-center justify-end w-auto z-1 absolute right-0 inset-y-0"
        onClick={() => sliderRef.current.slickNext()}
      />
    )
  }

  function PrevArrow() {
    return (
      <PrevButton
        classList="flex items-center justify-start w-auto z-1 absolute left-0 inset-y-0"
        onClick={() => sliderRef.current.slickPrev()}
      />
    )
  }

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    draggable: true,
    arrows: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    centerMode: true,
    variableWidth: false,
    centerPadding: 0,
  }

  return (
    <React.StrictMode>
      {imageItems ? (
        <div
          className={`overflow-x-hidden relative py-16 z-0 block w-full inset-x-0 bg-white bg-opacity-90 transform ${
            isModalOpen ? "-translate-y-1000px" : "translate-y-0"
          }`}
          ref={stickyBar}
          data-layer-component="slider-component"
        >
          <div className="px-2.5 lg:px-4 xl:px-16 container">
            <div className="flex items-stretch justify-center flex-wrap overflow-hidden">
              <div className="w-full flex items-center justify-center relative">
                <Slider
                  {...settings}
                  ref={sliderRef}
                  className="categories-slick w-full relative"
                >
                  {imageItems.map(image => (
                    <div key={uid(16)}>
                      <div className="flex-col md:flex-row flex justify-center items-center">
                        <div className="mb-10 w-64 md:w-80 relative pb-60 md:pb-72 lg:pb-80">
                          <ImageContentful
                            sizes="70vw"
                            src={image.imageIcon.url}
                            width={image.imageIcon.width}
                            height={image.imageIcon.height}
                            title={image.imageIcon.captionAlt || image.imageIcon.title || image.imageIcon.fileName}
                            layout="fill"
                            classList="object-cover object-center rounded-full"
                          />
                          {image.videoId && image.source ? (
                            <div className="absolute inset-0 flex items-center justify-center z-1">
                              <ButtonPlayVideo
                                id={image.videoId}
                                source={image.source}
                                buttonClassList="w-20 h-20 text-white"
                              />
                            </div>
                          ) : null}
                        </div>
                        <div className="w-full md:w-8/12 relative px-6 md:pl-12">
                          <TitleUnderlined
                            content={title}
                            tag="h2"
                            titleAlign="Center Mobile"
                            lineWidth="w-24 mb-10"
                            classList="mb-2 font-nandos text-xxl2"
                          />
                          <div className={textClasslist}>
                            {documentToReactComponents(image.description.json)}
                          </div>
                          <p className="md:font-semibold">{image.name}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </React.StrictMode>
  )
}

export default SliderComponent
